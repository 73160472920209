import { Modal } from "antd";
import PropTypes from "prop-types";
import TotpLogin from "./TotpLogin";

const TotpModal = ({ open, onClose, authError, uid, onFinish }) => {
  const handleLogin = async (body) => {
    if (body.success) {
      onFinish();
      onClose();
    } else if (body.error) {
      console.error(body.error);
    }
  };

  return (
    <Modal
      title="Secure Authentication"
      closable={false}
      open={open}
      onCancel={onClose}
      destroyOnClose
      okText="Continue"
      okButtonProps={{ disabled: true }}
    >
      <div className="my-4">
        To continue, please enter the 6-digit verification code generated by
        your <span className="font-medium">authenticator app</span>.
      </div>
      {open && (
        <TotpLogin authError={authError} uid={uid} onFinish={handleLogin} />
      )}
    </Modal>
  );
};

TotpModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  authError: PropTypes.object.isRequired,
  uid: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default TotpModal;
