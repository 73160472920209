import {
  getAuth,
  getMultiFactorResolver,
  TotpMultiFactorGenerator,
} from "firebase/auth";
import PropTypes from "prop-types";
import { useState } from "react";
import { Alert } from "antd";
import TotpCodeInput from "./TotpCodeInput";

const TotpLogin = ({ authError, uid, onFinish }) => {
  const [error, setError] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleLogin = async (otpCode) => {
    setError(null);
    const mfaResolver = getMultiFactorResolver(getAuth(), authError);

    setLoading(true);
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
      uid,
      otpCode
    );
    try {
      const userCredential = await mfaResolver.resolveSignIn(
        multiFactorAssertion
      );
      console.log({ userCredential });

      setLoading(false);

      if (onFinish) {
        onFinish({
          success: true,
          user: userCredential.user,
        });
      }
      // Successfully signed in!
    } catch (err) {
      if (err.code === "auth/invalid-verification-code") {
        setError({ message: "Invalid code, please try again" });
      } else if (err.code === "auth/quota-exceeded") {
        setError({
          message:
            "You've tried too many times to log in. Please try again later.",
        });
      } else if (err.code === "auth/totp-challenge-timeout") {
        setError({
          message:
            "That took too long, you'll need to log in again with your email and password.",
        });
      } else {
        setError(err);
      }
      console.error(err);
      setLoading(false);
      // Invalid or expired OTP.
    }
  };

  return (
    <div>
      {error && <Alert type="error" showIcon message={error.message} />}
      <TotpCodeInput
        onFinish={handleLogin}
        loading={loading}
        clear={!!error}
        className="my-6"
      />
    </div>
  );
};

TotpLogin.propTypes = {
  authError: PropTypes.object.isRequired,
  uid: PropTypes.string.isRequired,
};

export default TotpLogin;
