import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  getMultiFactorResolver,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";
import { Button, Form, Input, Modal, message, Alert } from "antd";
import { SITE_URL } from "../../config";

import TotpModal from "./TotpModal";
import fire from "../../fire";

const db = fire.firestore();

const BaseAuth = (props) => {
  const {
    signUpRedirect,
    onFinish,
    defaultMode,
    subtitle,
    showLogo,
    showHeader,
    showName,
  } = props;

  const [formValues, setFormValues] = useState({});
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);

  const router = useRouter();

  const [mode, setMode] = useState(defaultMode || "login");
  useEffect(() => {
    if (defaultMode) {
      setMode(defaultMode);
    }
  }, [defaultMode]);

  const [email, setEmail] = useState(router.query.email || "");
  const auth = getAuth();

  const [form] = Form.useForm();

  const createUserDoc = async (user, values) => {
    const data = {
      Email: user.email,
    };
    if (values.name) {
      data.Name = values.name;
    }
    try {
      await db.collection("User").doc(user.uid).set(data, { merge: true });
    } catch (err) {
      message.error(err.message, err.code);
    }
  };

  useEffect(() => {
    if (router.query.email) {
      setEmail(router.query.email);
      form.setFieldsValue({ email: router.query.email });
    }
    if (router.query.mode) {
      setMode(router.query.mode);
    }
    if (router.query.name) {
      form.setFieldsValue({ name: router.query.name });
    }
  }, [router.query, form]);

  const [authError, setAuthError] = useState(null);
  const [uid, setUid] = useState(null);
  const [needsTotp, setNeedsTotp] = useState(false);

  const [capsLockOn, setCapsLockOn] = useState(false);

  const handleKeyPress = (event) => {
    const capsLockOn = event.getModifierState("CapsLock");
    setCapsLockOn(capsLockOn);
  };

  const login = async (values) => {
    const { email: formEmail, password } = values;

    setProcessing(true);

    try {
      await signInWithEmailAndPassword(auth, formEmail, password);
      onFinish(values);
    } catch (err) {
      const { code: errorCode, message: errorMessage } = err;

      if (errorCode === "auth/wrong-password") {
        setError("The password is incorrect.");
      } else if (errorCode === "auth/user-not-found") {
        setError(
          "We have no record of an account with that email address. Do you want to create a new account instead?"
        );
      } else if (errorCode === "auth/multi-factor-auth-required") {
        setFormValues(values);
        const mfaResolver = getMultiFactorResolver(auth, err);
        const enrolledFactors = mfaResolver.hints.map((info) => info.factorId);
        if (enrolledFactors.includes("totp")) {
          const totpIndex = mfaResolver.hints.findIndex(
            (info) => info.factorId === "totp"
          );
          setAuthError(err);
          setUid(mfaResolver.hints[totpIndex].uid);
          setNeedsTotp(true);
        }
      } else {
        setError(errorMessage);
      }
      setProcessing(false);
    }
  };

  const handleTotp = () => {
    setNeedsTotp(false);
    onFinish(formValues);
  };

  const checkIfIosWebview = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
      if (safari) {
        return false;
      } else if (!safari) {
        return true;
      }
    } else {
      return false;
    }
  };

  const handleGoogle = async () => {
    const provider = new GoogleAuthProvider();

    if (checkIfIosWebview()) {
      signInWithRedirect(auth, provider);
    } else {
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // The signed-in user info.
          setError(null);
          await createUserDoc(result.user, {
            email: result.user.email,
            name: result.user.displayName,
          });
          onFinish({
            email: result.user.email,
            name: result.user.displayName,
          });

          // ...
        })
        .catch((err) => {
          // Handle Errors here.
          setError(err.message);

          // ...
        });
    }
  };

  const signUp = async (values) => {
    const { email: formEmail, password } = values;

    setProcessing(true);
    window.analytics.track("Create Account");
    try {
      await createUserWithEmailAndPassword(auth, formEmail, password);
      await createUserDoc(auth.currentUser, values);
      onFinish(values);
    } catch (err) {
      if (err.code === "auth/email-already-in-use") {
        await login(values);
      } else if (err.code === "auth/weak-password") {
        setError("The password is too weak.");
        setProcessing(false);
      } else {
        setError(err.message);
        setProcessing(false);
      }
    }
  };

  const handleForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email, {
        url: window.location.href,
      });

      Modal.success({
        content:
          "We've sent a password reset link to your email. Reset your password, then come back to this page.",
      });
    } catch (err) {
      if (
        err.message ===
        `sendPasswordResetEmail failed: First argument "email" must be a valid string.`
      ) {
        message.error("Type in your email address first");
      } else if (err.code === "auth/user-not-found") {
        message.error(
          "We have no record of an account with that email address. Have you typed it right?"
        );
      } else {
        message.error(err.message);
      }
    }
  };

  return (
    <>
      {showLogo && (
        <img src="/plinth-logo.svg" alt="Plinth logo" className="h-8  mb-6" />
      )}

      {showHeader && (
        <>
          <h1 className="font-medium ">
            {mode === "login"
              ? "Log in to your account"
              : "Create Free Account"}
          </h1>
          <p>{subtitle || "Get started exploring grants"}</p>
        </>
      )}

      <TotpModal
        authError={authError}
        uid={uid}
        open={needsTotp}
        onFinish={handleTotp}
        onClose={() => setNeedsTotp(false)}
      />
      <button
        key="google"
        type="button"
        onClick={handleGoogle}
        className="hover:bg-gray-100 text-sm space-x-4 border justify-center flex w-full rounded-md p-3 my-4 border-gray-300 text-gray-900"
      >
        <svg
          viewBox="0 0 18 18"
          aria-hidden="true"
          style={{
            height: 20,
            width: 20,
          }}
          display="block"
        >
          <g fill="none" fillRule="evenodd">
            <path
              d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
              fill="#EA4335"
            />
            <path
              d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
              fill="#4285F4"
            />
            <path
              d="M3.88 10.78A5.54 5.54 0 013.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 000 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
              fill="#FBBC05"
            />
            <path
              d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
              fill="#34A853"
            />
            <path d="M0 0h18v18H0V0z" />
          </g>
        </svg>
        <span className="">
          {mode === "login" ? "Log in" : "Sign up"} with Google
        </span>
      </button>
      <div className="relative mt-6">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex justify-center text-sm font-medium leading-6">
          <span className="bg-white px-6 text-gray-900">Or continue with</span>
        </div>
      </div>
      <Form
        form={form}
        layout="vertical"
        className="mt-6"
        onFinish={mode === "login" ? login : signUp}
      >
        {showName && mode === "signup" && (
          <Form.Item
            required
            className="mb-4"
            rules={[{ required: true }]}
            name="name"
            label="Full Name"
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          required
          className="mb-4"
          rules={[{ required: true }]}
          name="email"
          label="Email Address"
          initialValue={email}
        >
          <Input type="email" onChange={(e) => setEmail(e.target.value)} />
        </Form.Item>
        <Form.Item
          className="mb-4"
          required
          rules={[{ required: true }]}
          name="password"
          label="Password"
        >
          <Input
            type="password"
            onKeyDown={handleKeyPress}
            onKeyUp={handleKeyPress}
          />
        </Form.Item>

        {capsLockOn && (
          <Alert
            message="Caps Lock is on"
            type="info"
            showIcon
            className="mb-4"
          />
        )}

        {error && (
          <div className="bg-red-50 border border-red-200 p-2 rounded-md text-red-500 text-sm font-medium mb-4">
            {error}
          </div>
        )}
        {mode === "login" && (
          <div className="text-center mb-2">
            Forgotten your password? <br />
            <button
              type="button"
              className="cursor-pointer font-bold"
              onClick={handleForgotPassword}
            >
              Send a reminder?
            </button>
          </div>
        )}

        <Form.Item>
          <Button
            loading={processing}
            size="large"
            type="primary"
            htmlType="submit"
            block
          >
            {mode === "login" ? "Log in" : "Sign Up"}
          </Button>
        </Form.Item>
        {mode === "signup" && (
          <p className="text-sm text-center mb-4">
            By signing up, I accept the plinth{" "}
            <a
              href={`https://${SITE_URL}/terms`}
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              Terms of Service
            </a>{" "}
            and acknowledge the{" "}
            <a
              href="https://docs.google.com/document/d/1sUIW3yEgS3g0XOj3kAeK0hLk3DI65__5QoT7PVXIIiI/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              Privacy Policy
            </a>
            .
          </p>
        )}
      </Form>
      <div className="text-center">
        {mode === "login"
          ? "Don't have an account? "
          : "Already have an account? "}

        <button
          type="button"
          onClick={() => {
            if (signUpRedirect) {
              if (mode === "login") {
                router.push(signUpRedirect);
              }
            } else {
              setMode(mode === "login" ? "signup" : "login");
            }
          }}
          className="border-b border-accent font-bold text-primary"
        >
          {mode === "login" ? "Sign up" : "Sign in"}
        </button>
      </div>
    </>
  );
};

BaseAuth.propTypes = {
  defaultMode: PropTypes.string,
  onFinish: PropTypes.func,
  showLogo: PropTypes.bool,
  showHeader: PropTypes.bool,
  showName: PropTypes.bool,
  subtitle: PropTypes.string,
  signUpRedirect: PropTypes.string,
};

BaseAuth.defaultProps = {
  defaultMode: "login",
  onFinish: () => {},
  signUpRedirect: null,
  showHeader: false,
  showName: false,
  showLogo: false,
  subtitle: "Get started exploring grants",
};

export default BaseAuth;
